import { css, StyleSheet } from 'aphrodite/no-important';
import { ensureProtocol, getQueryObj, setQueryObj } from './uri';
import { UserProfile } from '../survey-tool/declarations/auth-api';
import defaultStyles from '../survey-tool/default-styles/default';
import { insertTrackingTags } from './tracking-tags';
import { logError } from './log';
import { appEnv } from '../app-config';

let eventOnAppLoadFired = false;

export function eventOnAppLoad(profile: UserProfile): UserProfile {
  if (eventOnAppLoadFired) {
    return profile;
  }
  // retrieve referrer from query
  const query = getQueryObj();
  const referrer = query.referrer || document.referrer || null;
  // notify ah-api with PP_APP_ONLOAD event
  const imgUrl = `${
    `${ensureProtocol(profile.instanceUrl)}/api/v1/${profile.instanceCode}` +
    `/events/record?eventName=PP_APP_ONLOAD&pp-token=${profile.ppToken}&pp-panel=${profile.panelKey}&eventData=`
  }${encodeURIComponent(JSON.stringify({ referrer }))}`;
  const img = document.createElement('img');
  img.setAttribute('style', 'width: 0px; height: 0px;position: absolute; ');
  img.className = css(styles.track);
  img.setAttribute('src', imgUrl);
  document.body.appendChild(img);
  // delete referrer from query
  delete query.referrer;
  // update the url in the browser
  setQueryObj(query);
  // insert tracking tags
  try {
    insertTrackingTags(profile);
  } catch (err) {
    logError(`insertTrackingTags -> ${err}`);
  }
  // make sure this is not executed more than once per lifetime of the app
  eventOnAppLoadFired = true;
  return profile;
}

let _faviconUrl: string;
export function setPageFaviconUrl(faviconUrl: string) {
  if (!faviconUrl || _faviconUrl === faviconUrl) {
    return;
  }
  const link = document.querySelector<HTMLLinkElement>('link[rel*="icon"]');
  if (link) {
    _faviconUrl = faviconUrl;
    link.href = _faviconUrl;
  }
}

let _title: string;
export function setPageTitle(title: string): void {
  if (!title) {
    return;
  }

  let newTitle = title;
  if (appEnv !== 'production') {
    newTitle += ` - ${appEnv}`;
  }
  if (process.env.NODE_ENV !== 'production') {
    newTitle += ` (nodeEnv=${process.env.NODE_ENV})`;
  }

  if (newTitle === _title) {
    return;
  }
  _title = newTitle;
  document.title = _title;
}

const styles = StyleSheet.create({
  track: { ...{ ...defaultStyles.trackingPixelContainer } } as any,
});
