import { RedemptionConfiguration } from '../../survey-tool/declarations/ah-api';
import * as types from '../actions/action-types';
import { RedemptionAction } from '../actions/redemption-actions';

type RedemptionReducerState = RedemptionConfiguration[] | null;

export default function redemptionReducer(
  state: RedemptionReducerState = null,
  action: RedemptionAction
): RedemptionReducerState {
  switch (action.type) {
    case types.LOAD_REDEMPTIONS_SUCCESS:
      return action.redemptions;
    default:
      return state;
  }
}
